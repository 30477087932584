// card
// 海鲜卡
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'card',
  children: [
    {
      path: '/card',
      name: 'card',
      component: () => import('@/views/card/index.vue'),
      meta: {
        title: '海鲜卡管理',
        icon: 'guanyu'
      }
    },
    {
      path: '/card/detail/:id',
      name: 'cardDetail',
      component: () => import('@/views/card/compont/detail.vue'),
      meta: {
        title: '海鲜卡详情'
      }
    },
    {
      path: '/card/import',
      name: 'cardImport',
      component: () => import('@/views/card/import/index'),
      meta: {
        title: 'excel导入'
      }
    }
  ]
}
