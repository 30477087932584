import { createRouter, createWebHashHistory } from 'vue-router'
import { menuSrore } from '@/store/menu'
import pinia from '@/store/store'
import layout from '@/layout/index.vue'
import organizationRole from './modules/organizationRole'
import organizationStaff from './modules/organizationStaff'
import organizationAuthority from './modules/organizationAuthority'
import operate from './modules/operate'
import aboutUs from './modules/aboutUs'
import candidate from './modules/candidate'
import classification from './modules/classification'
import accountStaff from './modules/accountStaff'
import accountCustomer from './modules/accountCustomer'
import card from './modules/card'

// 公开路由表
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    component: layout,
    redirect: '/rotation',
    children: [
      // // 二维码管理
      {
        path: '/rotation',

        name: 'rotation',
        component: () => import('@/views/about-us/index.vue'),
        meta: {
          title: '公司简介',
          icon: 'lunbo'
        }
      },

      // 404
      {
        path: '/404',
        name: '/404',
        component: () => import('@/views/error-page/404.vue')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/404.vue')
      }
    ]
  }
]

// 私有路由表
export const privateRoutes = [
  classification,
  candidate,
  aboutUs,
  operate,
  organizationRole,
  organizationStaff,
  organizationAuthority,
  accountStaff,
  accountCustomer,
  card
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})
const MSrore = menuSrore(pinia)
export function resetRouter() {
  if (MSrore.permission) {
    const { menus } = MSrore.permission
    if (Array.isArray(menus)) {
      menus.forEach((menu) => {
        router.removeRoute(menu)
      })
    }

    // 退出时还需要将 pinia 中的数据置空
    MSrore.permission = {}
  }
}
export default router
