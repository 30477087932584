<template>
  <div class="">
    <div class="logo-container">
      <img
        v-if="appstore.sidebarOpened"
        src="../../../assets/img/logo_big.png" style="width: 80%;height: 40%" />
     <!-- <img v-else src="../../../assets/img/logo_min.jpg" /> -->
    </div>
    <!-- el-scrollbar :   -->
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu.vue'
import { appStore } from '../../../store/app'
// store
const appstore = appStore()

// const logoHight = 44
</script>

<style lang="scss" scoped>
.logo-container {
  // height: 44px;
  height: v-bind(logoHight) + 'px';
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-title {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }
}
</style>
